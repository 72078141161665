import React from "react"
import Styled from "./styled.module.css"

function Help({ obj }) {
  return (
    <section className={Styled.section}>
      <div className="container">
        <div className={Styled.wrapper}>
          <div className={Styled.helpWrapper}>
            <div className={Styled.help}>
              <h1 className="md-title bold">How we can help</h1>
              <div dangerouslySetInnerHTML={{ __html: obj.help }} className={`${Styled.helpText} md-text`}>
              </div>
            </div>
            <div className={Styled.thumb}>
              <h1 className="bold lg-text text-center">Mission</h1>
              <p className={`${Styled.thumbText} lg-text`}>
                {obj.mission}
              </p>
            </div>
          </div>
          <section>
            <div dangerouslySetInnerHTML={{ __html: obj.content }} className={Styled.textWrapper}>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Help
