import React from "react"
import { Link } from "gatsby"
import Divider from "../../../assets/img/divider.svg"
import Styled from "./styled.module.css"

function Hero({ obj }) {
  return (
    <section className={`${Styled.section} ${Styled.overlay}`}>
      <img
        alt={obj.path}
        className={Styled.img}
        src={require(`../../../assets/img/industries/hero/${obj.path}.jpg`)}
      ></img>
      <div className={`${Styled.heroWrapper} container `}>
        <div className={Styled.heroDescription}>
          <Link className={`${Styled.explore} xs-text bold`} to="/industries">
            EXPLORE ALL INDUSTRIES
          </Link>
          <h1
            dangerouslySetInnerHTML={{ __html: obj.title }}
            className="md-title bold"
          ></h1>
          <p dangerouslySetInnerHTML={{ __html: obj.herosubtitle }} className="lg-text">
          </p>
        </div>
      </div>
      <img
        alt="Hero Divider"
        width="100%"
        className={Styled.divider}
        src={Divider}
      ></img>
    </section>
  )
}

export default Hero
