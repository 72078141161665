import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import Hero from "./Hero"
import Help from "./Help"
import Services from "../../components/Services"
import Trusted from "../../components/Trusted"
import { graphql } from "gatsby"

export default function Template({ data }) {

  const { markdownRemark } = data

  return (
    <>
      <Helmet
        title="Integra Global Solutions - Outsourcing solutions for any firm of any size"
        meta={[
          {
            name: `description`,
            content:
              "Good people making a positive difference to companies worldwide. We partner with small and medium businesses to achieve high performance through technology driven outsourcing solutions.",
          },
          {
            name: "keywords",
            content:
              "integra global solutions, outsourcing, bpo outsourcing, rpa bots, business process outsourcing, kpo outsourcing, customised bpo services",
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Integra Global Solutions - Outsourcing solutions for any firm of any size" />
        <meta
          property="og:description"
          content="Good people making a positive difference to companies worldwide. We partner with small and medium businesses to achieve high performance through technology driven outsourcing solutions."
        />
        <meta
          property="og:image"
          content="https://www.globalintegra.com/integra-featured-image.png"
        />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta name="twitter:title" content="Integra Global Solutions - Outsourcing solutions for any firm of any size" />
        <meta
          name="twitter:description"
          content="Good people making a positive difference to companies worldwide. We partner with small and medium businesses to achieve high performance through technology driven outsourcing solutions."
        />
        <meta
          name="twitter:image"
          content="https://www.globalintegra.com/integra-featured-image.png"
        ></meta>
      </Helmet>
      <Layout>
        <main>
          <Hero obj={markdownRemark.frontmatter} />
          <Help obj={markdownRemark.frontmatter} />
          <Services />
          <Trusted />
        </main>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        subtitle
        mission
        herosubtitle
        path
        content
        help
        thumbnailtext
      }
    }
  }
`
